/* react-calendar-heatmap styles
*
* All of the styles in this file are optional and configurable!
* The github and gitlab color scales are provided for reference.
*/

.react-calendar-heatmap text {
 font-size: 10px;
 fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
 font-size: 5px;
}

.react-calendar-heatmap rect:hover {
 stroke: #555;
 stroke-width: 1px;
}

/*
* Default color scale
*/

.react-calendar-heatmap .color-empty {
 fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
 fill: #8cc665;
}

/*
* Github color scale
*/
.react-calendar-heatmap .color-github-0-light {
    fill: #E8EAE9;
}
.react-calendar-heatmap .color-github-0 {
 fill: #FDF7F0;
}
.react-calendar-heatmap .color-github-1 {
    fill: #F7DEC0;
}
.react-calendar-heatmap .color-github-2 {
 fill: #EFBD80;
}
.react-calendar-heatmap .color-github-3 {
 fill: #E79C40;
}
.react-calendar-heatmap .color-github-4 {
 fill: #E38B20;
}
.react-calendar-heatmap .color-github-5 {
 fill: #DF7A00;
}

/*
* Gitlab color scale
*/

.react-calendar-heatmap .color-gitlab-0 {
 fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
 fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
 fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
 fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
 fill: #254e77;
}
