@font-face {
    font-family: 'Century';
    src: local("GOTHIC"),
    url(../fonts/CenturyGothic/GOTHIC.TTF) format('truetype');
}
@font-face {
    font-family: 'PreciIMS';
    src: local('PreciIMS'), url('../fonts/IMS/PreciIMS.ttf') format('truetype');
}
@font-face {
    font-family: 'SlimIMS';
    src: local('SlimIMS'), url('../fonts/SlimIMS/SlimIMS.ttf') format('truetype');
}